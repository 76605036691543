import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { PostHogProvider} from 'posthog-js/react'

const options = {
  api_host: "https://us.i.posthog.com"
}

function getPosthogKeyForCurrentEnv() {
    const url = window.location.href;
  if(url.includes("localhost")) {
    return "phc_ejCDtjzUmH9bxLhRWTrmSY8bNqOK5ehwKpwPjuxxQ91";
  } else if (url.includes("preview")) {
    return "phc_maXYXegquZl7idNfUuj8gi6JXSzkkypKYEmOoVxpRNR";
  } else {
    return "phc_1lOmTw8EqMViZhxi91ESM2qwChTJfhW2iiMfW12kfJz";
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PostHogProvider 
      apiKey={getPosthogKeyForCurrentEnv()}
      options={options}
    >
      <App />
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
