import { useState, useEffect } from 'react';
import './output.css';
import AppointmentScheduler from './AppointmentScheduler';
import { usePostHog } from 'posthog-js/react';

function InterviewSignUp() {
  const posthog = usePostHog();

  function backToHomeClicked() {
    posthog.capture("Back to Home button clicked");
  }

  return (
    <div class="px-3 pb-10">
      <div class="flex justify-center">
          <a
            className="btn btn-secondary"
            href="/"
            onClick={backToHomeClicked}>
            Back to home
          </a>
        </div>
      <AppointmentScheduler /> 
    </div>
  );
}

export default InterviewSignUp;