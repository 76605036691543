import './App.css';
import './output.css';
import TabContainer from './TabContainer.js';
import instagramIcon from './Instagram-icon.png';
import facebookIcon from './Facebook-icon.png';
import blueskyIcon from './Bluesky-icon.svg';
import linkedinIcon from './Linkedin-icon.png';
import youtubeIcon from './Youtube-icon.svg';
import { usePostHog } from 'posthog-js/react';

function Home() {
	const posthog = usePostHog();

	function privacyPolicyLinkClicked() {
		posthog.capture("Privacy Policy link clicked");
	}

	function termsLinkClicked() {
		posthog.capture("Terms link clicked");
	}

	function instagramLinkClicked() {
		posthog.capture("Instagram link clicked");
	}
	
	function facebookLinkClicked() {
		posthog.capture("Facebook link clicked");
	}

	function blueskyLinkClicked() {
		posthog.capture("Bluesky link clicked");
	}
	
	function linkedInLinkClicked() {
		posthog.capture("LinkedIn link clicked");
	}

	return (
	<div className="App">

		<TabContainer>
		</TabContainer>
		<footer class="bg-white">
			<div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
				<div class="sm:flex sm:items-center sm:justify-between">
					<span class="text-sm text-gray-500 sm:text-center grid grid-cols-1 md:grid-cols-2 grid-flow-row">
						<div className="pr-10 pb-2.5">
							2024 <a href="https://uheart.co" class="hover:underline">uheart</a>. All Rights Reserved.
						</div>
						<div>
							<a
								href="/PrivacyPolicy"
								target="_blank"
								class="hover:underline text-gray-700 font-medium"
								onClick={privacyPolicyLinkClicked}>
								Privacy Policy
							</a>

							<a 
								href="/Terms"
								target="_blank"
								class="hover:underline text-gray-700 font-medium pl-10"
								onClick={termsLinkClicked}>
								Terms
							</a>
						</div> 
					</span>
					<div class="flex mt-4 justify-start sm:mt-0 md:justify-between">
						<a href="https://www.instagram.com/uheart.co/"
							class="p-2 max-w-[35px] max-h-15"
							target="_blank"
							onClick={instagramLinkClicked}>
							<img src={instagramIcon} />
						</a>
						<a href="https://www.facebook.com/uheart.co"
							class="p-2 max-w-[35px] max-h-15"
							target="_blank"
							onClick={facebookLinkClicked}>
							<img src={facebookIcon} />
						</a>
						<a href="https://bsky.app/profile/uheart.bsky.social"
							class="p-2 max-w-[35px] max-h-15"
							target="_blank"
							onClick={blueskyLinkClicked}>
							<img className="w-15 h-15" src={blueskyIcon} />
						</a>
						<a href="https://www.linkedin.com/company/uheart"
							class="p-2 max-w-[35px] max-h-15"
							target="_blank"
							onClick={linkedInLinkClicked}>
							<img className="w-15 h-15" src={linkedinIcon} />
						</a>     
					</div>
				</div>
			</div>
		</footer>
	</div>
	);
}

export default Home;
