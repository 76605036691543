import { useState } from 'react';
import { Turnstile } from '@marsidev/react-turnstile';
import Modal from 'react-modal';
import AppointmentScheduler from './AppointmentScheduler';
import EmailRegistration from './EmailRegistration';
import { usePostHog } from 'posthog-js/react';

function SignUpForm() {
	const posthog = usePostHog();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isTurnstileValid, setTurnstileValid] = useState(false);

	function participateInInterviewLinkClicked() {
		posthog.capture("Participate in Interview button clicked");
	}

		function venmoLinkClicked() {
		posthog.capture("Contribute on Venmo button clicked");
	}

	return (
		<div className="font-semibold">
			<include src="https://challenges.cloudflare.com/turnstile/v0/api.js"></include>
			{ /* -- <iframe width="420" height="315"
				src="https://www.youtube.com/embed/tRHhyocsbv8">
			</iframe> --> */}
			<div className="pt-4 grid grid-cols-1 md:grid-cols-3 grid-flow-row gap-4">
				<div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-secondary flex flex-col justify-between">
					<div>
						<div className="text-center pb-7 text-5xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
							Interview
						</div>
						<div className="text-center leading-[26.5px]">
								Love is the reason we are here! For better or worse, dating apps have become the most used method to find love. We want to talk with actual people about their experiences on Dating Apps. Sign up for a single brief virtual interview to share your experiences with us to better help all find love.
						</div>
					</div>
					<div class="text-center pt-7">
						<a
							className="btn btn-primary"
							onClick={participateInInterviewLinkClicked}
							href="/InterviewSignUp">
							Participate in Interview 
						</a>
					</div>
					{ /** <Modal
						isOpen={isModalOpen}
						contentLabel="Example Modal"
					>
						<button
							className="btn btn-secondary float-right"
							onClick={() => setIsModalOpen(false)}>
							
							X
						</button>
						
						<AppointmentScheduler isTurnstileValid={isTurnstileValid} setTurnstileValid={setTurnstileValid}/>
					</Modal> */ }
				</div>
				<EmailRegistration isTurnstileValid={isTurnstileValid} setTurnstileValid={setTurnstileValid} />
				<div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-secondary flex flex-col justify-between">
					<div>
						<div className="text-center pb-7 text-5xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
							Contribute
						</div>
						<div  className="text-center leading-[26.5px]">
							Are we a bit crazy for this idea?!?! We think maybe it’s possible folks might even want to spare several bucks here. Since we're just two people starting from scratch to try to re-invent the dating game, we have a bit of a hill to overcome. While contributions are by no means necessary nor expected, they're something that could help invigorate our dream. Even a contribution as small as $1 goes a long way.
						</div>
					</div>
					<div class="text-center pt-7">
						<a
							className="btn btn-primary"
							href="https://venmo.com/u/uheart"
							target="_blank"
							onClick={venmoLinkClicked}>
							
							
							Contribute on Venmo
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SignUpForm;