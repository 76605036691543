import { useState, useEffect } from 'react';
import CalCalendar from './CalCalendar.js';
import { usePostHog } from 'posthog-js/react';

function AppointmentScheduler() {
  const posthog = usePostHog();
  const interviewConditions = [
    {id: "demoReq", label: "Demographic Information"},
    {id: "timeAvailReq", label: "Availablility"}, 
    {id: "marketReq", label: "Relationship Goals"}, 
    {id: "techReq", label: "Dating App Demo"}
  ];

  const mCalSrc = getCalendarKeysForCurrentEnv()[0];
  const fCalSrc = getCalendarKeysForCurrentEnv()[1];
  const [isSchedulerDisplayed, setDisplayScheduler] = useState(false);
  const [isConfirmButtonEnabled, setEnableConfirmButton] = useState(false);
  const [calSrc, setCalSrc] = useState('');
  const [interviewConditionsState, setinterviewConditionsState] = useState(
    new Array(interviewConditions.length).fill(false)
  );
  useEffect(() => {
    setEnableConfirmButton(areInterviewConditionsToggled() && calSrc !== '');
  }, [interviewConditionsState]);

  useEffect(() => {
    setEnableConfirmButton(areInterviewConditionsToggled() && calSrc !== '');
  }, [calSrc]);

  function displayScheduler() {
    posthog.capture("Confirm interview conditions button clicked");
    setDisplayScheduler(true);
  }

  function genderSelectionChanged(genderSelection) {
    if(genderSelection == "male") {
      setCalSrc(mCalSrc);
    } else if (genderSelection == "female") {
      setCalSrc(fCalSrc);
    } else {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      if(dd%2 == 0) {
        setCalSrc(mCalSrc);
      } else {
        setCalSrc(fCalSrc);
      }
    }
  }

  function interviewConditionToggled(indexOfToggledItem) {
    const updatedCheckedState = interviewConditionsState.map((item, index) => {
        if(interviewConditions[index].id == indexOfToggledItem) {
          posthog.capture(interviewConditions[index].label + " checkbox toggled");
          return !item;
        } else {
          return item;
        }
      }
    );
    setinterviewConditionsState(updatedCheckedState);

  };

  function areInterviewConditionsToggled() {
    for(let i = 0; i < interviewConditionsState.length; i++) {
      if(!interviewConditionsState[i]) {
        return false;
      }
    }
    return true;
  }

  function getCalendarKeysForCurrentEnv() {
    const url = window.location.href;
    if(url.includes("localhost")) {
      return ["derp","derp-f"];
    } else if (url.includes("preview")) {
      return ["interview-session-m-test", "interview-session-f-test"];
    } else {
      return ["interview-session-m", "interview-session-f"];
    }
  }

  return (
    <div>
      <div className={isSchedulerDisplayed ? 'hidden' : ''}>
        Welcome! Thanks for having interest in talking about your user experiences on dating apps.
        <br/>
        <br/>
        Before you schedule an interview, we have some very quick qualifications to go over.
        If your answer is “Yes” to each one, please select the box next to each qualification.
        <br/>
        <br/>
        If your answer is “No” to any qualification (you leave any box blank), that is absolutely alright. 
        At this time, you will only be able to schedule an interview by leaving no boxes blank.
        <br/>
        <br/>
        Thanks again for your support! Here are the questions:
        <br/>
        <div class="mt-3 p-3 border border-primary">
          <input
            type="checkbox"
            id="demoReq"
            name="demoReq"
            value="demoReq"
            class="w-[20px] h-[20px]"
            onChange={() => interviewConditionToggled('demoReq')}
            required
          />
          <label className="required-field pl-2 font-semibold underline">
            Demographic Information
          </label>
          <div>
            - I am (at minimum) the age of 21 years old as of today.
            <br/>
            - I currently have either a full-time or part-time job.
            <br/>
            - I currently am involved in higher education.
          </div>
        </div>
        <div class={`mt-3 p-3 border border-primary ${!interviewConditionsState[0] ? "opacity-10" : ""}`}>
          <input
            type="checkbox"
            id="timeAvailReq"
            name="timeAvailReq"
            value="timeAvailReq"
            class="w-[20px] h-[20px]"
            onChange={() => interviewConditionToggled('timeAvailReq')}
            required
          />
          <label className="required-field pl-2 font-semibold underline">Availablility</label>
          <div>
            - I am available for a 1-hour period to be remotely video interviewed.
            <br/>
            - I currently live in the state of North Carolina or South Carolina.
          </div>
        </div>
        <div class={`mt-3 p-3 border border-primary ${!interviewConditionsState[0] || !interviewConditionsState[1] ? "opacity-10" : ""}`}>
          <input
            type="checkbox"
            id="marketReq"
            name="marketReq"
            value="marketReq"
            class="w-[20px] h-[20px]"
            onChange={() => interviewConditionToggled('marketReq')}
            required
          />
          <label className="required-field pl-2 font-semibold underline">
            Relationship Goals
          </label>
          <div>
            - I am currently seeking a life-long relationship.
            <br/>
            - I once or more (1+) per week think about finding a life-long relationship.
            <br/>
            - I once or more (1+) per week find myself unhappy using a dating app.
            <br/>
            - I once or more (1+) per week am actively using a dating app for myself.
          </div>
        </div>
        <div class={`mt-3 p-3 border border-primary ${!interviewConditionsState[0] || !interviewConditionsState[1] || !interviewConditionsState[2] ? "opacity-10" : ""}`}>
          <input
            type="checkbox"
            id="techReq"
            name="techReq"
            value="techReq"
            class="w-[20px] h-[20px]"
            onChange={() => interviewConditionToggled('techReq')}
            required
          />
          <label className="required-field pl-2 font-semibold underline">
            Dating App Demo
          </label>
          <div>
            - I have a smartphone or tablet device that I can use to join for this full interview.
            <br/>
            - I have (at minimum) 1 dating app that I am actively using downloaded onto the same smartphone or tablet device I joined with for this interview.
            <br/>
            - With that same smartphone or tablet device I joined for this interview, I agree to screenshare while using (at minimum) one 1 dating app that I am currently active on.
          </div>
        </div>
        <div class="pt-5">
          <label className="required-field block m-1">Gender</label>
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block"
            onChange={e => genderSelectionChanged(e.target.value)}>
            <option value="" selected disabled hidden>Select gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="notListed">Not Listed</option>
          </select>
        </div>
        <button
          className="btn btn-primary"
          onClick={displayScheduler}
          disabled={!isConfirmButtonEnabled} >
          
          Confirm
        </button>
      </div>
      <div style={{display: isSchedulerDisplayed && calSrc == mCalSrc ? 'block' : 'none'}}>
        <CalCalendar calendarKey={mCalSrc}  />
      </div>
      <div style={{display: isSchedulerDisplayed && calSrc == fCalSrc ? 'block' : 'none'}}>
        <CalCalendar calendarKey={fCalSrc}  />
      </div>
    </div>
  );
}

export default AppointmentScheduler;