import Tab from './Tab.js';
import SignUpForm from './SignUpForm.js';
import AboutUvuli from './AboutUvuli.js';
import AboutTheFounders from './AboutTheFounders.js';
import { useState } from 'react';
import logo from './uheart-logo.png';
import { usePostHog } from 'posthog-js/react';

function TabContainer({children}) {
  const [activeTab, setActiveTab] = useState(0);
  const posthog = usePostHog();

  function getInvolvedTabClicked() {
    posthog.capture('Get Involved tab clicked');
    setActiveTab(0);  
  }

  function aboutTabClicked() {
    posthog.capture('About tab clicked');
    setActiveTab(1);  
  }

  return (
    <div>
      <div className="text-sm font-medium text-center text-gray-500 dark:text-gray-400 flex justify-between">
        <img className="max-w-16 p-1.5" src={logo}  alt="logo" />
        <span className="-mb-px">
          <Tab tabName="Get Involved"
            isActive={activeTab === 0}
            tabSelected={getInvolvedTabClicked} />
          <Tab tabName="About"
            isActive={activeTab === 1}
            tabSelected={aboutTabClicked} />
        </span>
      </div>
      <div class="overflow-visible  px-3 py-10 bg-gradient-to-br from-primary to-secondary flex justify-center">
        {activeTab === 0 && <SignUpForm />}
        {activeTab === 1 && <AboutUvuli />}
      </div>
    </div>

  );
}

export default TabContainer;