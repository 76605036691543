import Cal, { getCalApi } from "@calcom/embed-react";
import { useEffect } from "react";
export default function CalCalendar({calendarKey}) {
	useEffect(()=>{
	  (async function () {
		const cal = await getCalApi();
		cal("ui",
			{
				theme: "light",
				"cssVarsPerTheme":
					{
						light: { "cal-brand": "#41bfe0"},
        				dark: {"cal-brand":"#41bfe0"}
        			},
        		"hideEventTypeDetails":false,
        		"layout":"month_view"
        	}
        );
	  })();
	}, []);
	return <Cal namespace={"uheart/" + calendarKey}
	  calLink={"uheart/" + calendarKey}
	  style={{width:"100%",height:"100%",overflow:"scroll"}}
	  config={{"layout":"month_view"}}
	  
	/>;
};
  
