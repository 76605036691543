import photoOfRichy from './sexyrexy.jpeg';
import photoOfAlexander from './photoOfAlexander.png';

function AboutUvuli() {

  return (
    <div class="pt-4 grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-7">
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-secondary flex flex-col justify-between">
        <p>
          Hey! My name is Richy Kandora and I am a professional software engineer with a love of all sports.s
          <br/>
          <br/>
          As someone who had been looking for a long-term relationship, it was eye-opening to me to see how many &quot;bad actor&quot; businesses there are in the dating space. 
          <br/>
          <br/>
          Over the years through my own personal experience on the apps and building a great rapport with my therapist, I decided I wanted to try to change the industry.
          <br/>
          <br/>
          Thus, I started uheart: a research program focused on championing emotionally-intelligent dating experiences by interviewing people about their usage on dating apps.
          <br/>
          <br/>
          Along with my co-founders, I'm excited for this journey and to hopefully make a positive change in the world!
        </p>
        <div class="pt-15">
          <img class="object-center" src={photoOfRichy}  alt="photo" />
        </div>
      </div>
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-secondary flex flex-col justify-between">
        <p>
          Hi there, I’m Alexander Fattell. Serial entrepreneur, tech venture capitalist, and fisher. I mean who doesn’t reeeeeally love a fish pic on dating apps.
          <br/>
          <br/>
          I was literally on a live dating show where it just so happens that both of my co-founders were in the audience that night.
          <br/>
          <br/> 
          After painstakingly spending an hour of the show reassuring my blind date that I wasn’t a catfish, Richy introduced himself at the after party.
          <br/>
          <br/>
          I shared that I had 14 dating app accounts yet not one of them was a good user experience. Richy told me about this research program being put together called uheart.
          <br/>
          <br/>
          I realized here is a team dedicated to discovering a more reliable, fun, and trustworthy experience to have real, unconditional, and forever love by directly listening to folks.
          <br/>
          <br/>
          Joining uheart as a co-founder, I’m jazzed up for these interviews to get started!
        </p>
        <div class="pt-10">
          <img class="object-center" src={photoOfAlexander}  alt="photo" />
        </div>
      </div>
    </div>
  );
}

export default AboutUvuli;